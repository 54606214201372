<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.transaction')">
      <Tabs value="washing" @on-click="changeTab">
        <!--            new tab             -->
        <TabPane :label="$t('message.washingMachineFarm')" name="washing">
          <b-form>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-2 my-1">
                {{ $t("message.year") }} :
                <br />
                <b-form-select v-model="timeSel" :options="language == 'th' ? timeOptTH : timeOptEN"
                  v-on:change="selectTime" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-1">
                {{ $t("message.machineIdFarm") }} :
                <br />
                <multi-list-select :list="machineData" option-value="machineId" option-text="name"
                  :selected-items="selectedMachine" :placeholder="$t('message.all')"
                  @select="onSelectMachine"></multi-list-select>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                {{ $t("message.deviceId") }} :
                <br />
                <b-form-input v-model="searchData.deviceId" />
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                {{ $t("message.refNumber") }} :
                <br />
                <b-form-input v-model="searchData.refNumber" type="number" />
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-1">
                {{ $t("message.selectDateTime") }} :
                <br />
                <div class="table-responsive">
                  <date-time-picker v-model="searchData.datetime"></date-time-picker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-1">
                {{ $t("message.product") }} :
                <br />
                <multi-list-select :list="ProductItems" option-value="id" option-text="name"
                  :selected-items="selectedProduct" :placeholder="$t('message.all')"
                  @select="onSelect"></multi-list-select>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                {{ $t("message.paymentType") }}:
                <br />
                <b-form-select v-model="searchData.paymentType" :options="$t('message.arrays.payment')" />
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
                {{ $t("message.status") }} :
                <br />
                <b-form-select v-model="searchData.status" :options="$t('message.arrays.statusTrans')" />
              </div>
              <div class="col-sm-6 col-md-6 col-lg- col-xl-4 my-3">
                <b-btn class="col-sm-12 col-md-4 ml-12 mr-1 my-1" variant="primary"
                  v-on:click="searchFn({ excel: false })">
                  <i class="fa fa-search"></i>
                  &nbsp;{{ $t("message.search") }}
                </b-btn>

                <b-btn class="col-sm-12 col-md-4 ml-12 my-2 mr-1" v-on:click="searchFn({ excel: true })"
                  variant="success" v-if="$isRole('owner', role_id)">
                  <i class="fa fa-download"></i>
                  &nbsp;{{ $t("message.export") }}
                </b-btn>
              </div>
            </div>
            <label class="row">
              <label class="col-sdx-12 col-mdx-2 col-lgx-2 col-xlx-4 my-1">
                {{ $t('message.mainTransaction') }} :
                &nbsp;{{ totalRows | formatNumber }}
              </label>
              <label class="col-sdx-12 col-mdx-2 col-lgx-2 col-xlx-4 my-1">
                {{ $t('message.subTransaction') }} :
                &nbsp;{{ totalSubRows | formatNumber }}
              </label>
              <label class="col-sdx-12 col-mdx-2 col-lgx-2 col-xlx-4 my-1">
                {{ $t('message.income') }} :
                &nbsp;{{ summary.all | formatNumber }}
              </label>
            </label>
            <label class="row">
              <label class="col-sdx-12 col-mdx-2 col-lgx-2 col-xlx-4 my-1"
                v-if="!$isRole('admin', this.role_id) && !$isRole('callcenter', this.role_id) || this.selectedMachine.length > 0">{{
                  $t('message.payByCash')
                }}: &nbsp;{{ summary.cash | formatNumber }}</label>
              <label class="col-sdx-12 col-mdx-2 col-lgx-2 col-xlx-4 my-1"
                v-if="!$isRole('admin', this.role_id) && !$isRole('callcenter', this.role_id) || this.selectedMachine.length > 0">{{
                  $t('message.payByQr')
                }} : &nbsp;{{ summary.qr | formatNumber }}</label>
              <label class="col-sdx-12 col-mdx-2 col-lgx-2 col-xlx-4 my-1"
                v-if="!$isRole('admin', this.role_id) && !$isRole('callcenter', this.role_id) || this.selectedMachine.length > 0">{{
                  $t('message.usedCreditBalance')
                }}: {{ summary.useCredit | formatNumber }}</label>
              <label class="col-sdx-12 col-mdx-2 col-lgx-2 col-xlx-4 my-1"
                v-if="!$isRole('admin', this.role_id) && !$isRole('callcenter', this.role_id) || this.selectedMachine.length > 0">{{
                  $t('message.topupCredit')
                }}:{{ summary.topUpCreate | formatNumber }}</label>
            </label>
          </b-form>
          <div class="table-responsive">
            <Table stripe row-key="transactionId" size="small" :row-class-name="rowClassName"
              :columns="columnTransactions" :data="rowData.rows" :load-data="handleLoadData"></Table>
          </div>
          <br />
          <Page :total="totalRows" :page-size="itemperPage" @on-change="changePage" :current="currentPage" />

          <!-- <Page :total="1000" :page-size="100" @on-change="changePage" :current="5" /> -->
        </TabPane>

        <!--            new tab             -->
        <TabPane :label="$t('message.addCredit')" name="addCredit">
          <b-form>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-1">
                {{ $t("message.machineId") }} :
                <br />
                <multi-list-select :list="machineData" option-value="machineId" option-text="name"
                  :selected-items="selectedMachine" placeholder="All" @select="onSelectMachine"></multi-list-select>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.refNumber") }} :
                <br />
                <b-form-input v-model="searchData.refNumber" type="number" />
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.paymentType") }}:
                <br />
                <b-form-select v-model="searchData.paymentType" :options="$t('message.arrays.payment')" />
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 my-1">
                {{ $t("message.status") }} :
                <br />
                <b-form-select v-model="searchData.status" :options="$t('message.arrays.statusTrans')" />
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                {{ $t("message.selectDateTime") }} :
                <br />
                <div class="table-responsive">
                  <date-time-picker v-model="searchData.datetime"></date-time-picker>
                </div>
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-3">
                <b-btn class="col-sm-12 col-md-4 ml-1 mr-1 my-1" variant="primary"
                  v-on:click="searchFn({ excel: false })">
                  <i class="fa fa-search"></i>
                  &nbsp;{{ $t("message.search") }}
                </b-btn>

                <b-btn class="col-sm-12 col-md-4 ml-1 my-2 mr-1" v-on:click="searchFn({ excel: true })"
                  variant="success" v-if="$isRole('owner', role_id)">
                  <i class="icon-docs"></i>
                  &nbsp;{{ $t("message.export") }}
                </b-btn>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-sd-12 col-md-6 col-ml-6 col-lg-6 col-xl-2 my-1">{{ $t('message.transactions') }} :{{
              totalRows | formatNumber }}</div>
              <div class="col-sd-12 col-md-6 col-ml-6 col-lg-6 col-xl-2 my-1"
                v-if="!$isRole('admin', this.role_id) && !$isRole('callcenter', this.role_id) || this.selectedMachine.length > 0">
                {{ $t('message.allRevenue') }} : {{ summary.all | formatNumber }}</div>
              <div class="col-sd-12 col-md-6 col-ml-6 col-lg-6 col-xl-2 my-1"
                v-if="!$isRole('admin', this.role_id) && !$isRole('callcenter', this.role_id) || this.selectedMachine.length > 0">
                {{ $t('message.payByCash') }} : {{ summary.cash | formatNumber }}</div>
              <div class="col-sd-12 col-md-6 col-ml-6 col-lg-6 col-xl-2 my-1"
                v-if="!$isRole('admin', this.role_id) && !$isRole('callcenter', this.role_id) || this.selectedMachine.length > 0">
                {{ $t('message.payByQr') }} : {{ summary.qr | formatNumber }}</div>
            </div>
            <br />
          </b-form>
          <div class="table-responsive">
            <Table stripe row-key="transactionId" size="small" :columns="columnAddCredits" :data="rowData.rows" />
          </div>
          <br />
          <Page :total="totalRows" :page-size="itemperPage" @on-change="changePage" />
        </TabPane>
      </Tabs>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'
import webServices from '../script'
import DateTimePicker from '../components/DateTimePicker/Custom.vue'

import FileSaver from 'file-saver'
import { MultiSelect, MultiListSelect } from '../custom_modules/search-select'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty'
import axios from 'axios'

//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

export default {
  name: 'orders',
  components: {
    // DatePicker,
    DateTimePicker,
    MultiSelect,
    MultiListSelect,
    VueJsonPretty
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022' }
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022' }
      ],
      language: window.localStorage.getItem("language"),
      role_id: window.localStorage.getItem('roleID'),
      userBusiness: window.localStorage.getItem('business'),
      rowData: {},
      totalRows: 0,
      totalSubRows: 0,
      currentPage: 1,
      itemperPage: 50,
      searchData: {
        // datetime: [moment(moment().toDate().setHours(0)).toDate(), moment(moment().toDate().setHours(23)).toDate()],
        datetime: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
        id: '',
        machineId: '',
        deviceId: '',
        productId: '',
        qty: '',
        price: '',
        acceptorMoney: '',
        changerMoney: '',
        status: '',
        paymentType: '',
        refNumber: '',
        type: 'washing',
        byPage: null
      },
      searchData_export: {},
      file_name: 'Report',
      branch: '',
      fromdate: '',
      todate: '',
      startDate: '',
      endDate: '',
      ProductItems: [],
      selectedProduct: [],
      title: [],
      summary: {},
      machineData: [],
      selectedMachine: [],
      sum: {
        changer: {
          coin: {},
          bill: {}
        },
        acceptor: {
          coin: {},
          bill: {}
        }
      },
      columnTransactions: [
        // {
        //   title: this.$t('message.transactionId'),
        //   key: 'transactionId',
        //   minWidth: 180,
        //   tree: true
        // },
        {
          title: this.$t('message.machineId'),
          key: 'machineId',
          minWidth: 90,
          align: 'center',
          tree: true
        },
        {
          title: this.$t('message.machineName'),
          // key: 'machine.name',
          minWidth: 120,
          align: 'left',
          ellipsis: true,
          // tooltip: true,
          render: (h, params) => {
            // return h('span', params.row.machine.name)
            return h(
              'Poptip',
              {
                props: {
                  trigger: 'hover',
                  content: params.row.machine.name
                }
              },
              [h('span', params.row.machine.name)]
            )
          }
        },
        {
          title: this.$t('message.deviceId'),
          key: 'deviceId',
          minWidth: 100,
          align: 'center'
        },
        {
          title: this.$t('message.product'),
          // key: 'productId',
          minWidth: 120,
          align: 'left',
          ellipsis: true,
          // tooltip: true,
          render: (h, params) => {
            if (params.row.paymentType === 'addCredit') {
              return h('div', this.$t('message.deductCredit'))
            } else if (params.row.paymentType === 'deductCredit') {
              return h('div', this.$t('message.deductCredit'))
            } else {
              return h(
                'Poptip',
                {
                  props: {
                    trigger: 'hover',
                    content: params.row.product.name
                  }
                },
                [h('span', params.row.product.name)]
              )
            }
          }
        },
        {
          title: this.$t('message.refNumber'),
          key: 'refNumber',
          minWidth: 140,
          align: 'right'
        },
        {
          title: this.$t('message.price'),
          key: 'price',
          minWidth: 80,
          align: 'right',
          render: (h, params) => h('span', Vue.filter('formatNumber')(params.row.price))
        },
        {
          title: this.$t('message.insert'),
          minWidth: 80,
          align: 'right',
          render: (h, params) => {
            if (params.row.paymentType === 'CASH') {
              return this.renderInsert(h, params)
            } else {
              return h('span', Vue.filter('formatNumber')(params.row.price))
            }
          }
        },
        {
          title: this.$t('message.creditBefore'),
          key: 'creditCMBefore',
          minWidth: 80,
          align: 'right',
          render: (h, params) => h('span', Vue.filter('formatNumber')(params.row.creditCMBefore))
        },
        {
          title: this.$t('message.creditAfter'),
          key: 'creditCMAfter',
          minWidth: 80,
          align: 'right',
          render: (h, params) => h('span', Vue.filter('formatNumber')(params.row.creditCMAfter))
        },
        {
          title: this.$t('message.paymentType'),
          minWidth: 80,
          align: 'center',
          render: this.showPaymentStatus
        },
        {
          title: this.$t('message.status'),
          minWidth: 80,
          align: 'center',
          render: this.transactionStatus
        },
        {
          title: this.$t('message.date'),
          minWidth: 106,
          align: 'center',
          render: (h, params) => h('span', moment(params.row.createdAt).format('YYYY-MM-DD HH:mm'))
        },
        {
          title: this.$t('message.detail'),
          // slot: 'action',
          width: 80,
          align: 'center',
          render: this.renderDetail
        }
      ],
      columnAddCredits: [
        {
          title: this.$t('message.machineIdFarm'),
          key: 'machineId',
          minWidth: 60,
          align: 'center'
        },
        {
          title: this.$t('message.refNumber'),
          key: 'refNumber',
          minWidth: 130,
          align: 'right'
        },
        {
          title: this.$t('message.insert'),
          minWidth: 80,
          align: 'right',
          render: (h, params) => {
            if (params.row.paymentType === 'CASH') {
              return this.renderInsert(h, params)
            } else {
              return h('span', Vue.filter('formatNumber')(params.row.price))
            }
          }
        },
        {
          title: this.$t('message.creditAfter'),
          key: 'creditCMAfter',
          minWidth: 80,
          align: 'right',
          render: (h, params) => h('span', Vue.filter('formatNumber')(params.row.creditCMAfter))
        },
        {
          title: this.$t('message.paymentType'),
          minWidth: 80,
          align: 'center',
          render: this.showPaymentStatus
        },
        {
          title: this.$t('message.status'),
          minWidth: 80,
          align: 'center',
          render: this.transactionStatus
        },
        {
          title: this.$t('message.date'),
          minWidth: 106,
          align: 'center',
          render: (h, params) => h('span', moment(params.row.createdAt).format('YYYY-MM-DD HH:mm'))
        },
        {
          title: this.$t('message.detail'),
          // slot: 'action',
          width: 80,
          align: 'center',
          render: this.renderDetail
        }
      ]
    }
  },
  async beforeMount() {
    this.api = axios.create({
      baseURL: this.axios.defaults.baseURL
    })

    this.api.defaults.headers.common['Authorization'] = this.api.defaults.headers.common['Authorization']
    console.log('beforeMount')
    console.log('machineId.length', this.searchData.machineId.length)
    console.log('machineId', this.searchData.machineId)
    console.log('this.searchData', this.searchData)

    this.setParams(this.$route.query)

    await this.getProduct()
    await this.getMachine()
    this.selectedProduct = this.ProductItems.filter(data => data.id == this.searchData.productId)
    this.selectedMachine = this.machineData.filter(data => data.machineId === this.searchData.machineId)

    if (!this.$isRole('admin', this.role_id) && !this.$isRole('callcenter', this.role_id)) {
      await this.getTransactions()
    }

    if (this.$route.query.machineId) {
      console.log('data ', this.$route.query)
      this.searchFn()
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },
    setParams(query) {
      if (Object.keys(query).length != 0) {
        console.log('setParams', query)
        this.fromdate = query.from
        this.todate = query.to
        this.searchData.datetime = [moment(query.from).toDate(), moment(query.to).toDate()]
        this.searchData.productId = query.productId || ''
        this.searchData.machineId = query.machineId || ''
        this.searchData.refNumber = query.refNumber || ''

        console.log('this.searchData', this.searchData)
      }
    },
    rowClassName() {
      return 'demo-table-info-row'
    },
    showPaymentStatus(h, params) {
      if (params.row.paymentDetails != null && params.row.paymentDetails.coupon) {
        params.row.paymentType = 'COUPON'
      }
      return this.paymentStatus(h, params.row.paymentType, params.row.status_paymentType)
    },
    paymentStatus(h, type, status_paymentType) {
      switch (type) {
        case 'CASH':
          return h('Tag', { props: { color: 'gold' }, slot: 'extra' }, 'Cash')
        case 'PROMPT PAY':
          return h('Tag', { props: { color: 'primary' }, slot: 'extra' }, 'PromptPay')
        case 'LINE PAY':
          return h('Tag', { props: { color: 'success' }, slot: 'extra' }, 'LinePay')
        case 'RABBIT':
          return h('Tag', { props: { color: 'orange' }, slot: 'extra' }, 'Rabbit')
        case 'addCredit':
          return h('Tag', { props: { color: 'green' }, slot: 'extra' }, '+credit')
        case 'deductCredit':
          return h('Tag', { props: { color: 'red' }, slot: 'extra' }, '-credit')
        case 'COUPON':
          // return h('Tag', { props: { color: '#FFA2D3' }, slot: 'extra' }, 'Coupon')
          return h(
            'Poptip',
            {
              props: {
                trigger: 'hover',
                content: status_paymentType
              },
              slot: 'extra'
            },
            [h('Tag', { props: { color: '#FFA2D3' } }, 'Coupon')]
          )
        case 'REFUND':
          return h('Tag', { props: { color: 'red' }, slot: 'extra' }, 'Refund')
        case 'WECHAT':
          return h('Tag', { props: { color: 'green' }, slot: 'extra' }, 'Wechat')
        case 'AIRPAY':
          return h('Tag', { props: { color: 'blue' }, slot: 'extra' }, 'AirPay')
        case 'TRUEMONEY':
          return h('Tag', { props: { color: 'red' }, slot: 'extra' }, 'Truemoney')
        case 'ALIPAY':
          return h('Tag', { props: { color: 'blue' }, slot: 'extra' }, 'AliPay')
        case 'SHOPEEPAY':
          return h('Tag', { props: { color: 'warning' }, slot: 'extra' }, 'ShopeePay')
        default:
          return h('Tag', { props: { color: 'default' }, slot: 'extra' }, type)
      }
    },

    tagStatus(h, status) {
      switch (status) {
        case 'SUCCESS':
          return h('Tag', { props: { color: 'success' }, slot: 'extra' }, 'Success')
        case 'PROCESS':
          return h('Tag', { props: { color: 'warning' }, slot: 'extra' }, 'Process')
        case 'CANCEL_BY_USER':
          return h('Tag', { props: { color: 'default' }, slot: 'extra' }, 'Cancel By User')
        case 'CANCEL_BY_ADMIN':
          return h('Tag', { props: { color: 'default' }, slot: 'extra' }, 'Cancel By Admin')
        case 'CANCEL_BY_MC_FAIL':
          return h('Tag', { props: { color: 'default' }, slot: 'extra' }, 'Cancel By Mc Fail')
        case 'CANCEL_PAYMENT':
          return h('Tag', { props: { color: 'default' }, slot: 'extra' }, 'Cancel Payment')
        case 'CANCEL_TIMEOUT':
          return h('Tag', { props: { color: 'default' }, slot: 'extra' }, 'Cancel Timeout')
        case 'CANCEL':
          return h('Tag', { props: { color: 'default' }, slot: 'extra' }, 'Cancel')
        case 'FAILED':
          return h('Tag', { props: { color: 'error' }, slot: 'extra' }, 'Success')
        default:
          return h('Tag', status)
      }
    },

    showTime(h, updatedAt) {
      return h('Key', { slot: 'extra' }, updatedAt)
    },

    space(h) {
      return h('Space', { slot: 'extra' }, ' ')
    },

    transactionStatus(h, params) {
      switch (params.row.status) {
        case 'SUCCESS':
          return h('Tag', { props: { color: 'success' } }, 'Success')
        case 'PROCESS':
          return h('Tag', { props: { color: 'warning' } }, 'Process')
        case 'CANCEL_BY_USER':
        case 'CANCEL_BY_ADMIN':
        case 'CANCEL_BY_MC_FAIL':
        case 'CANCEL_PAYMENT':
        case 'CANCEL_TIMEOUT':
        case 'CANCEL':
          return h(
            'Poptip',
            {
              props: {
                trigger: 'hover',
                content: params.row.status
              }
            },
            [h('Tag', { props: { color: 'default' } }, 'Cancel')]
          )
        case 'FAILED':
          return h('Tag', { props: { color: 'error' } }, 'Success')
        default:
          return h('Tag', params.row.status)
      }
    },
    renderInsert(h, params) {
      let money = []

      for (const [key, value] of Object.entries(params.row.acceptor.bill)) {
        if (value != 0) {
          money.push({ title: `${this.$t('message.banknote')} ${key}`, value: value })
        }
      }

      for (const [key, value] of Object.entries(params.row.acceptor.coin)) {
        if (value != 0) {
          money.push({ title: `${this.$t('message.coin')} ${key}`, value: value })
        }
      }

      if (params.row.acceptorMoney != 0) {
        return h('Poptip', { props: { trigger: 'hover', placement: 'left' } }, [
          Vue.filter('formatNumber')(params.row.acceptorMoney),
          h('div', { slot: 'content' }, [
            h(
              'ul',
              money.map(item => {
                // console.log(item)
                return h(
                  'li',
                  {
                    style: {
                      textAlign: 'center',
                      padding: '4px'
                    }
                  },
                  `${item.title}: ${item.value}`
                )
              })
            )
          ])
        ])
      } else {
        return h('SPAN', Vue.filter('formatNumber')(params.row.acceptorMoney))
      }
    },
    renderDetail(h, params) {
      return h('Button', {
        props: {
          type: 'primary',
          size: 'small',
          icon: 'ios-expand'
        },
        style: {
          marginRight: '5px'
        },
        on: {
          click: () => {
            this.showDetails(params.row)
          }
        }
      })
    },
    async getTransactions(page = 1) {
      this.$Progress.start()
      this.summary = []
      this.currentPage = page

      const rows = this.itemperPage
      const params = {
        ...this.searchData,
        page: page,
        rows: rows,
        from: this.fromdate,
        to: this.todate
      }

      this.searchData_export = params

      if (Array.isArray(params.machineId)) {
        params.machineId = this.searchData.machineId.join(',')
      }

      if (Array.isArray(params.productId)) {
        params.productId = this.searchData.productId.join(',')
      }

      // var startweek =  moment('2022').add(32, 'weeks').startOf('week').format('DD/MM/YYYY');
      // var endweek =  moment('2022').add(32, 'weeks').endOf('week').format('DD/MM/YYYY');
      // console.log('week 32 2022', startweek,'to',endweek )

      // console.log('params', params)

      delete params.id
      delete params.datetime

      // await this.api
      await this.api
        .get(`/transactions`, { params })
        .then(res => {
          this.$Progress.finish()
          this.rowData = res.data

          // console.log('this.rowData ',    this.rowData )

          for (var i = 0; i < this.rowData.rows.length; i++) {
            // console.log('i',  this.rowData.rows[i])
            this.rowData.rows[i]['status_paymentType'] = this.rowData.rows[i].paymentType
            if (this.rowData.rows[i].status == 'CANCEL_BY_MC_FAIL' || this.rowData.rows[i].status == 'CANCEL_PAYMENT' || this.rowData.rows[i].status == 'CANCEL_TIMEOUT') {
              this.rowData.rows[i].price = 0
            }
            let dateStart = moment(this.rowData.rows[i].createdAt, 'YYYY-MM-DD HH:mm')
            let dateEnd = moment(this.rowData.rows[i].updatedAt, 'YYYY-MM-DD HH:mm')

            let diff = moment.duration(dateEnd.diff(dateStart))

            let diffDays = diff.days()
            let diffDaysFormatted = diffDays > 0 ? `0${diffDays}` : ``

            let diffHours = diff.hours()
            let diffHoursFormatted = diffHours < 10 ? `0${diffHours}` : `${diffHours}`

            let diffMinutes = diff.minutes()
            let diffMinutesFormatted = diffMinutes < 10 ? `0${diffMinutes}` : `${diffMinutes}`

            this.rowData.rows[i]['duration'] = `${diffDaysFormatted} ${diffHoursFormatted}:${diffMinutesFormatted}`
          }

          this.rowData.rows.forEach((row, idx) => {
            if (row.transactionType == 'MAIN') {
              this.rowData.rows[idx].children = []
              this.rowData.rows[idx]._loading = false
              console.log('this.rowData.rows[idx]', this.rowData.rows[idx])
            }
          })
          console.log('this.rowData', this.rowData.rows)
          this.totalRows = res.data.count
          this.totalSubRows = res.data.subCount
        })
        .catch(err => {
          this.$Progress.fail()
          console.log('error Orders')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })

      //summary
      if ((!this.$isRole('admin', this.role_id) && !this.$isRole('callcenter', this.role_id)) || this.selectedMachine.length > 0 || this.searchData.byPage == 'analyzeMonthly') {
        await this.api
          .get(`/transactions/summary`, { params })
          .then(res => {
            this.summary = res.data
            console.log('this.summary', this.summary)
            this.summary.useCredit_raw = 0
            this.summary.topUpCreate_raw = 0

            var useCredit = this.summary.useCredit
            var topUpCreate = this.summary.topUpCreate

            // console.log('useCredit', useCredit, 'topUpCreat', topUpCreate)
            if (useCredit > topUpCreate) {
              this.summary.useCredit_raw = useCredit - topUpCreate
              console.log('ใช้เครดิต', useCredit - topUpCreate)
            } else if (useCredit < topUpCreate) {
              console.log('เครดิตสะสมเพิ่ม', topUpCreate - useCredit)
              this.summary.topUpCreate_raw = topUpCreate - useCredit
            } else {
              console.log('err')
            }
            console.log('useCredit', useCredit, 'topUpCreat', topUpCreate)
          })
          .catch(err => {
            this.$Progress.fail()
            console.log('error Orders')
            console.log(err)
            this.$toast.error({
              title: 'ERROR',
              message: 'เกิดข้อผิดพลาด'
            })
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
          })
      }
    },
    handleLoadData(item, callback) {
      const params = {
        transactionId: item.details.subTransaction.join()
      }

      this.api
        .get(`/subtransactions`, { params })
        .then(res => {
          this.$Progress.finish()
          callback(res.data.rows)
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR123',
            message: err
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    async getMachine() {
      this.$Progress.start()
      await this.api
        .get(`/machines/list`)
        .then(res => {
          this.$Progress.finish()
          this.machineData = res.data
          if (this.machineData.length == 1) {
            this.selectedMachine = this.machineData
          }
        })
        .catch(err => {
          this.$Progress.fail()
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    isAdmin(role) {
      return webServices.isAdmin(role)
    },
    isDealer(role) {
      return webServices.isDealer(role)
    },
    changePage(PageNum) {
      // window.scrollTo(0, 0)
      this.getTransactions(PageNum)
    },

    showAlert_ChooseOneBranch() {
      if (window.localStorage.getItem('language') == 'th') {
        var title = 'โปรดเลือกช่วงเวลาใหม่อีกครั้ง'
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = 'ปิด'
      } else {
        var title = 'Please select a new time again'
        var text = 'Cannot select more than 12 months.'
        var confirmButton = 'Close'
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: confirmButton
      })
    },

    showAlert_ChooseThanOneBranch() {
      if (window.localStorage.getItem('language') == 'th') {
        var title = 'โปรดเลือกช่วงเวลาใหม่อีกครั้ง'
        var text = 'เลือกมากกว่า 1 สาขา \n ' + 'ไม่สามารถเลือกเดือนได้มากกว่า 2 เดือน\n'
        var confirmButton = 'ปิด'
      } else {
        var title = 'Please select a new time again'
        var text = 'Choose than 1 branch, \n ' + 'Cannot select more than 2 months.\n'
        var confirmButton = 'Close'
      }

      this.$swal({
        title: title,
        html: '<pre>' + text + '</pre>',
        // text: text ,
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months
      months = (d2.getFullYear() - d1.getFullYear()) * 12
      months -= d1.getMonth()
      months += d2.getMonth()
      return months <= 0 ? 0 : months
    },

    searchFn(opt = { excel: false }) {
      console.log('searchFn')
      var localStorageUser = JSON.parse(window.localStorage.getItem('users'))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      if (this.searchData.datetime !== '') {
        var timeEnd = moment(this.searchData.datetime[1]).format('HH:mm:ss')
        if (timeEnd === '00:00:00' || timeEnd === '12:00:00 AM') {
          this.searchData.datetime[1].setHours(23, 59, 59, 999)
        }
        this.fromdate = moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        this.todate = moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss')
        // console.log(this.todate)
      } else {
        this.fromdate = ''
        this.todate = ''
      }

      if (this.searchData.type != 'washing') {
        this.transactionType = 'ALL'
      }

      var dateStart = new Date(this.searchData.datetime[0])
      var dateEnd = new Date(this.searchData.datetime[1])

      // case เลือก 1 สาขา จะเลือกช่วงเวลาได้มากสุด 1 ปี
      if (this.searchData.machineId.length == 1 || window.localStorage.getItem('roleID') == 'dealer') {
        console.log('เลือก 1 สาขา หรือ dealer')
        if (this.monthDiff(dateStart, dateEnd) < 12) {
          if (!opt.excel) {
            this.getTransactions(1)
            this.getProduct()
          } else {
            if (this.rowData.rows.length > 0) {
              this.exportExcel()
              // this.getTransactions(1)
            }
          }
        } else {
          console.log('เลือกเดือนได้สูงสุด 12 เดือน')
          this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
        }
        // case เลือกมากกว่า 1 สาขา จะเลือกช่วงเวลาได้มากสุด 2 เดือน
      } else {
        console.log('เลือกมากกว่า 1 สาขา')
        console.log('machineId.length', this.searchData.machineId.length)
        console.log('machineId', this.searchData.machineId)
        if (this.monthDiff(dateStart, dateEnd) < 2) {
          if (!opt.excel) {
            this.getTransactions(1)
            this.getProduct()
          } else {
            if (this.rowData.rows.length > 0) {
              this.exportExcel()
              // this.getTransactions(1)
            }
          }
        } else {
          console.log('เลือกเดือนได้สูงสุด 2 เดือน')
          this.showAlert_ChooseThanOneBranch() // เลือกเดือนได้สูงสุด 2 เดือน
        }
      }

      // if (!opt.excel) {
      //   this.getTransactions(1)
      //   this.getProduct()
      // } else {
      //   this.exportExcel()
      // }
    },
    async showDetails(data) {
      data.paymentDetails = data.paymentDetails == null ? {} : data.paymentDetails

      if (this.searchData.type == 'addCredit') {
        await this.$Modal.info({
          width: 600,
          render: h => {
            return h('CellGroup', [
              h('Cell', {
                props: {
                  title: this.$t('message.transactionId'),
                  extra: data.transactionId
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.refNumber'),
                  extra: data.refNumber
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.machineIdFarm'),
                  extra: data.machineId
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.product'),
                  extra: data.product.name
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.insert'),
                  // extra: String(Vue.filter('formatNumber')(data.acceptorMoney))
                  extra: data.paymentType == 'CASH' ? String(Vue.filter('formatNumber')(data.acceptorMoney)) : String(Vue.filter('formatNumber')(data.price))
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.creditBefore'),
                  extra: String(data.creditCMBefore)
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.creditAfter'),
                  extra: String(data.creditCMAfter)
                }
              }),
              h(
                'Cell',
                {
                  props: {
                    title: this.$t('message.paymentType')
                  }
                },
                data.paymentType == 'COUPON' ? [this.paymentStatus(h, data.status_paymentType), this.paymentStatus(h, data.paymentType)] : [this.paymentStatus(h, data.paymentType)]

                // data.paymentType != data.status_paymentType ? [this.paymentStatus(h, data.status_paymentType), this.paymentStatus(h, data.paymentType)] : [this.paymentStatus(h, data.paymentType)]
              ),
              h('Cell', {
                props: {
                  title: 'Ksher order no',
                  extra: data.paymentDetails.transId ? String(data.paymentDetails.transId) : '-'
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.date'),
                  extra: moment(data.createdAt).format('YYYY-MM-DD HH:mm')
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.success'),
                  extra: data.status === 'SUCCESS' ? moment(data.updatedAt).format('YYYY-MM-DD HH:mm') : '-'
                }
              })
            ])
          }
        })
      } else {
        this.$Progress.start()

        let sms = {}
        const params = {
          transactionId: data.transactionId,
          machineId: data.machineId
        }

        await this.api
          .get(`/sms/status`, { params })
          .then(res => {
            this.$Progress.finish()

            if (res.data) {
              sms = res.data
              // console.log(sms)
              sms.updatedAt = moment(sms.updatedAt).format('YYYY-MM-DD HH:mm:ss')
            }
          })
          .catch(err => {
            this.$Progress.fail()
            console.log('error @get SMS status')
            console.log(err)
            this.$toast.error({
              title: 'ERROR',
              message: err
            })
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
          })

        await this.$Modal.info({
          width: 600,
          // style: {
          //   margin: '0px',
          //   padding: '0px',
          // },
          render: h => {
            return h('CellGroup', [
              h('Cell', {
                props: {
                  title: this.$t('message.transactionId'),
                  extra: data.transactionId
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.refNumber'),
                  extra: data.refNumber
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.machineIdFarm'),
                  extra: data.machineId
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.deviceId'),
                  extra: String(data.deviceId)
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.slot'),
                  extra: String(data.slotId)
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.product'),
                  extra: data.product.name
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.price'),
                  extra: String(this.getPrice(data))
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.insert'),
                  extra: String(Vue.filter('formatNumber')(data.acceptorMoney))
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.creditBefore'),
                  extra: String(data.creditCMBefore)
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.creditAfter'),
                  extra: String(data.creditCMAfter)
                }
              }),
              h(
                'Cell',
                {
                  props: {
                    title: this.$t('message.paymentType')
                  }
                },
                data.paymentType == 'COUPON' ? [this.paymentStatus(h, data.status_paymentType), this.paymentStatus(h, data.paymentType)] : [this.paymentStatus(h, data.paymentType)]
              ),
              h('Cell', {
                props: {
                  title: 'Ksher order no',
                  extra: data.paymentDetails.transId ? String(data.paymentDetails.transId) : '-'
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.promotion'),
                  extra: data.paymentType === 'COUPON' ? data.paymentDetails.promotionName : '-'
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.couponRef'),
                  extra: data.paymentType === 'COUPON' ? data.paymentDetails.couponRef : '-'
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.transactionStartDate'),
                  extra: moment(data.createdAt).format('YYYY-MM-DD HH:mm')
                }
              }),
              h(
                'Cell',
                {
                  props: {
                    title: data.status === 'SUCCESS' || data.status === 'PROCESS' ? this.$t('message.transactionSuccessfulDate') : this.$t('message.transactionUnsuccessfulfulDate')
                    // extra: data.status === 'PROCESS' ? '-' : moment(data.updatedAt).format('YYYY-MM-DD HH:mm')
                  }
                },
                data.status === 'PROCESS' ? [this.tagStatus(h, data.status)] : [this.tagStatus(h, data.status), this.space(h), this.showTime(h, moment(data.updatedAt).format('YYYY-MM-DD HH:mm'))]
              ),

              h('Cell', {
                props: {
                  title: this.$t('message.transactionPeriod'),
                  extra: data.status === 'PROCESS' ? '-' : data.duration
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.smsStatus'),
                  extra: sms.success ? (sms.smsStatus ? String(sms.smsStatus) : 'กำลังส่ง') : '-'
                }
              }),
              h('Cell', {
                props: {
                  title: this.$t('message.transactionSendMessageDate'),
                  extra: sms.success ? String(sms.updatedAt) : '-'
                }
              })
            ])
          }
        })
      }
    },
    getPrice(data) {
      switch (data.status) {
        case 'PROCESS':
        case 'SUCCESS':
          return data.price
        default:
          return data.details.selectedPrices ? data.details.selectedPrices : data.price
      }
    },
    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
      this.searchData.name = this.selectedMachine.map(data => data.name.substr(9).replaceAll('\\r\\n', ''))
    },
    onSelect(items) {
      // console.log(items)
      this.selectedProduct = items
      this.searchData.productId = this.selectedProduct.map(data => data.id)
    },
    async getProduct() {
      const params = { type: this.searchData.type }
      await this.api
        .get(`/products/list`, { params })
        .then(res => {
          this.ProductItems = res.data
        })
        .catch(err => {
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
          console.log('error @get product ORDER')
          console.log(err)
        })
    },
    exportExcel() {
      // const params = {
      //   ...this.searchData,
      //   from: this.fromdate,
      //   to: this.todate,
      // }

      this.fromdate = moment(this.searchData_export.from).format('YYYY-MM-DD HH:mm:ss')
      this.todate = moment(this.searchData_export.to).format('YYYY-MM-DD HH:mm:ss')

      const params = this.searchData_export

      this.$Progress.start()

      if (Array.isArray(params.machineId)) {
        params.machineId = this.searchData.machineId.join(',')
      }

      if (Array.isArray(params.productId)) {
        params.productId = this.searchData.productId.join(',')
      }

      delete params.id
      delete params.datetime

      this.api({
        url: `/transactions/download`, // File URL Goes Here
        method: 'GET',
        params,

        responseType: 'blob'
      }).then(response => {
        if (this.searchData.type == 'washing') {
          this.file_name = 'Report_Daily_Usage'
        } else {
          this.file_name = 'Report_Daily_Credit'
        }

        if (this.searchData.machineId.length > 0) {
          this.branch = this.searchData.machineId
        } else {
          this.branch = '...'
        }

        FileSaver.saveAs(response.data, `${this.file_name}_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)
        this.$Progress.finish()
      })
    },
    changeTab(params) {
      this.rowData = {}
      this.totalRows = 0
      this.totalSubRows = 0
      this.summary = {}
      this.searchData.type = params
      if (!this.$isRole('admin', this.role_id) && !this.$isRole('callcenter', this.role_id)) {
        this.searchFn()
      }
    }
  }
}
</script>

<style>
@import './style.css';

.spanCustom {
  cursor: pointer;
  color: #20a8d8;
  border-color: #20a8d8;
  text-decoration: underline;
}

.spanCustom:hover {
  text-decoration: none;
  text-shadow: 1px 1px 1px #555;
}

.ivu-table-cell {
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 0px;
}

.ivu-table-small td {
  height: 30px;
}

.ivu-tag {
  font-size: 0.9em;
  margin: 0 2px 0 0;
  padding: 0 4px;
}
</style>
